.fc {
    width: 100%;
    margin: 0.2rem 0;
}
.fc__container {
    background-color: $menu-color;
    border-radius: 20px;
    padding: 1rem;
    cursor:pointer;
    justify-content: space-between;
}
.fc__titleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-basis: unset !important;
}
.fc__imageContainer {
    position: relative;
    padding: 1rem;
}

.fc__image {
    filter: invert(45%) sepia(12%) saturate(1083%) hue-rotate(135deg) brightness(92%) contrast(87%);
    // padding-right: .4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
}
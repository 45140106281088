.dashboardLayout__container {
    display: flex;
    background-color: $main-background;

    .dashboardLayout__content {
        height: 100vh;
        overflow: auto;
        flex-grow: 1;
    }

    .dashboardLayout__appBarSpacer {
        min-height: 64px;
    }

    .MuiDrawer-paperAnchorDockedLeft {
        background-color: $drawer-color;
    }

    .MuiAppBar-root {
        background-color: $appBar-color !important;
    }
    .MuiPaper-elevation4 {
        box-shadow: none;
    }
    .MuiDrawer-paperAnchorDockedLeft {
        border-right: none;
    }

    .MuiPaper-root {
        background-color: $dashboard-background !important;
    }
}

.sleepDialog {
    width: 50%;
    margin: auto;
    .MuiDialog-container {
        .MuiPaper-root {
            background-color: $menu-color;
        }
    }
    label,
    legend {
        color: rgb(192, 192, 192);
        font-size: 24px;
    }
    input {
        color: white;
        font-size: 16px;
    }
    svg {
        color: white;
    }
    span {
        color: white;
    }
}

.MuiClock-amButton {
    span {
        color: black;
    }
}

.MuiClock-pmButton {
    span {
        color: black;
    }
}

.sleepDiary__form {
    & > * {
        margin-bottom: 3rem !important;
    }
    .MuiSlider-markLabel {
        font-size: 12px;
    }

    // .MuiInputBase-root {
    //     margin-top: 2rem !important;
    // }
    // .MuiInputBase-input {
    //     width: unset !important;
    // }
}

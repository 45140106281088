.home {
    display: flex;
    gap: 40px;
    height: calc(100% - 125px);
    @include small {
        flex-direction: column;
    }
    &__left {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 50%;
    }
    &__right {
        width: 50%;
    }
    &__chat {
        height: 100%;
    }
    &.assessments {
        .item {
            background: linear-gradient(to top left, #000, $portal-background 75%) !important;
            display: flex;
            padding: 10px;
            margin: 3px;
            border-radius: 5px;
            cursor: pointer;
            .logo-container {
                height: 50px;
                width: 50px;
                margin-right: 1rem;
            }
            .logo {
                width: 45px;
                object-fit: contain;
            }
            .questionnaire-progression {
                position: absolute;
                border-radius: 50px;
                width: 20px;
                height: 20px;
                right: 15px;
                top: 25px;
            }
        }
    }
    &.intervention {
        .home__container {
        }
    }
    &.control {
        min-height: calc(100vh - 156px);
        .home__container {
            display: flex;
            &--left {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            &--right {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

.home__buttons {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    div {
        width: 100%;
    }
}

.home__cards {
    height: calc(100% - 155px);
}

.home__section {
    color: white;
    font-family: $Roboto;
}

.home__title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.home__help {
    z-index: 3;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home__helpContainer {
    // min-height: 200px;
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
}
.home__helpContainerCard {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
    cursor: pointer;
    padding-left: 20px;
}
.home__logoBackground {
    position: absolute;
    width: 125%;
    top: -50px;
    right: -75px;
    z-index: 1;
    opacity: 0.3;
}
.home__titleContainer {
    z-index: 3;
}
.home__image {
    z-index: 3;
    width: 50px;
}

.home__options {
    color: white;
}
.home__optionsContainer {
    gap: 1rem;
    align-self: flex-end;
}
.home__options__card {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    padding: 1rem 2rem;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse !important;
    gap: 1rem;
    height: min-content;
    cursor: pointer;
    button {
        .MuiTouchRipple-root {
            line-height: unset !important ;
        }
    }
}
.home__options__background {
    position: absolute;
    font-size: 300px;
    opacity: 0.3;
    right: -75px;
    top: -100px;
}
.home__options__image {
    color: #4ecbd3;
    font-size: 50px;
    z-index: 5;
}
.home__options__title {
    opacity: 1;
    z-index: 5;
    justify-content: flex-start !important;
    padding: 0 !important;
    line-height: unset !important;
}

.react-datepicker__input-container {
    width: inherit;
}

.react-datepicker-wrapper {
    width: 100%;
}

.login__container {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login__avatar {
        margin: 0.5rem;
        background-color: $progress-blue;
    }

    form {
        width: "100%"; // Fix IE 11 issue.
        margin: 0.5rem;
    }

    button {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
}

.login__backdrop {
    z-index: 1201;
    background: $dashboard-text;
    .login__spinner {
        position: absolute;
        top: 2rem;
    }
}
.login__content {
    &--login {
        .MuiInputBase-root {
            border-radius: 20px !important;
            background-color: white !important;
        }
        .MuiInputLabel-root {
            top: -11px !important;
            color: $menu-color !important;
            font-size: 1.3rem !important;
        }
        .MuiInputLabel-shrink {
            color: white !important;
        }
        fieldset {
            border: none !important;
        }
    }
}
.TEST {
    .MuiFormControlLabel-label {
        color: rgba(0, 0, 0, 0.6);
    }
}

.participant__dialog {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    textarea,
    li {
        font-family: $Roboto;
        color: rgba(0, 0, 0, 0.6) !important;
    }
}

.participant__ {
    &container {
    }

    &nav {
        .MuiListItem-root {
            padding-left: 1.5rem;
            background-color: lightgray;
            margin-bottom: 5px;
            border-radius: 5px;
        }

        text-decoration: none;

        .MuiTypography-body1 {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
        }

        svg {
            height: 1.8em;
            width: 1.8em;
        }

        &.active {
            .MuiListItem-root {
                background-color: $dashboard-text;
            }
        }
    }
}

.info__container {
    p {
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
    }
    li::marker {
        color: rgba(0, 0, 0, 0.6);
    }
}

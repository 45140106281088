.chat__container {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    border-radius: 20px;
    fieldset {
        border: none;
    }
    input {
        margin: 16.5px 0 16.5px 14px;
        padding: 0.5rem;
        background-color: #8d8d8d;
        border-radius: 20px;
    }
    label {
        top: 8px;
        left: 10px;
    }
    .chat__output {
        max-height: 400px;
        overflow: auto;
        padding: 10px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .chat__submit {
        color: $blue !important;
    }
}
.chatDashboard__container {
    max-height: 500px;
    overflow: auto;
    .chat__output {
        padding: 10px;
    }
    .chat__messages--user {
        color: #525252;
    }
    .chat__messages--content {
        background-color: #525252;
    }
}
.chat__messages {
    display: flex;
    flex-direction: column;
    &--user {
        font-weight: 700;
        font-size: 15px;
        margin: 10px 0 5px;
    }
    &--content {
        border-radius: 20px;
        padding: 5px 10px;
        font-size: 15px;
        font-weight: 500;
    }

}
.forgotPassword {
    .MuiDialog-container {
        .MuiPaper-root {
            background-color: $menu-color;
        }
    }
    &__contextText {
        color: white !important;
    }
    label {
        color: white;
    }
    input {
        color: white;
    }
}
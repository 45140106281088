.progress {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    border-radius: 20px;
    margin-top: 0.5rem !important;
    margin-left: 0 !important;
    margin-bottom: 1rem !important;
    width: 100% !important;
    div:nth-last-child(2) {
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        padding-bottom: 0.8rem;

    }
}

.MuiLinearProgress-barColorPrimary {
    background-color: $progress-blue !important;
}
.MuiLinearProgress-barColorSecondary {
    background-color: $progress-red !important;
}

.MuiLinearProgress-root {
    border-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.progress__topics{
    background-color: $menu-color;
    // padding: .5rem;
}
.progress__bar {
    padding-right: 16px;
    .MuiLinearProgress-root {
        background-color: black !important;
    }
}
.progress__topCorners {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.progress__worse {
    color: $progress-red;
    @include computer {
        font-size: 1.25rem !important;
    }
}
.progress__better {
    color: $progress-blue;
    @include computer {
        font-size: 1.25rem !important;
    }
}
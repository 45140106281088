.biometricDialog {
    .MuiDialog-container {
        .MuiPaper-root {
            background-color: $menu-color;
        }
    }
    .biometric-label {
        color: white;
        font-size: 18px;
    }
    fieldset {
        border-color: rgb(192, 192, 192);
    }
    input {
        color: white;
    }
}

.fg {
    td,
    th,
    tr {
        color: white;
    }

    span {
        color: white;
    }
    svg {
        svg {
            fill: white;
        }
        fill: white;
    }
    .MuiPaper-root {
        background-color: black;
    }
    color: white;
    padding: 2rem 4rem;
    .MuiBox-root {
        .MuiTypography-root {
            color: white;
        }
    }
}
.question__container {
    color: white;
    .MuiOutlinedInput-notchedOutline {
        border-color: white !important;
    }
    .MuiOutlinedInput-input {
        color: white !important;
    }
    .MuiCheckbox-colorPrimary {
        color: white;
    }
}
.question__label {
    color: white !important;
}
.question__labelHealth {
    color: white !important;
    label {
        color: white !important;
    }
    input {
        color: white !important;
    }
    .MuiInputBase-root {
        &::before {
            border-bottom-color: white;
        }
    }
}
.question__label__icon {
    svg {
        path {
            stroke: white !important;
        }
    }
}
.question__radio {
    svg {
        path {
            fill: $progress-blue !important;
        }
    }
}
.question__title {
    margin-bottom: 0.7rem;
}
.question__buttons__container {
    margin-top: 2rem !important;
    &:first-child {
        display: flex;
    }
}
.question__buttons {
    &:first-child {
        display: flex;
        justify-content: flex-end;
    }
    & > * {
        font-weight: 700 !important;
        border-radius: 20px !important;
        border: solid 2px $progress-blue !important;
    }
    &--back {
        background-color: #00000000 !important;
        color: white !important;
        &:hover {
            color: black !important;
            background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
        }
    }
    &--next {
        background-color: $progress-blue !important;
        h6 {
            color: black !important;
        }
        &:hover {
            h6 {
                color: white !important;
            }
            background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
        }
    }
    &--text {
        font-size: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
}
.question__chevron {
    font-size: 2rem;
}
.question__radioContainer {
    flex-direction: column !important;
}

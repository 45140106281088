.help {
    .help__accordion {
        background-color: $menu-color !important;
        color: white !important;
        border-radius: 20px !important;
        padding: 10px;
        border: none !important;
        position: unset !important;
        box-shadow: none;
        .MuiButtonBase-root {
            .MuiAccordionSummary-expandIconWrapper {
                svg {
                    color: $blue;
                    font-size: 4rem;
                }
            }
        }
    }
    .help__faq__questions {
        // font-size: 1rem;
    }

    .MuiDivider-root {
        background-color: $blue;
        height: 1.5px;
    }
    input, textarea {
        color: white;
    }
}
.MuiMenuItem-root {
    // font-size: 1rem !important;
}
.help__textbox {
    margin-bottom: 2rem !important;
    .MuiInputBase-root {
        background-color: rgba($color: #000000, $alpha: 0.4);
        border-radius: 20px;
    }
}
.help__content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}
.cognitiveTraining {
    margin-bottom: 2rem !important;
}
.neuropeak__logoContainer {
    background-color: black;
    border-radius: 20px;
}
.neuropeak__logo {
    padding: 1rem 0;
}
.ct__app {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    height: 100%;
}
.ct__neuropeak__content {
    border: 2px solid $progress-blue;
    border-radius: 20px;
    margin: 2rem;
    padding: 2rem;
}
.ct__neuropeak__projectContainer {
    padding-top: 1rem;
    display: flex;
    align-items: center;
}
.ct__neuropeak__btnArrow {
    // position: absolute;
    // right: 30px;
    font-size: 2.5rem;
    margin-right: 1rem;
}
.ct__neuropeak__input {
    background-color: black;
    border-radius: 20px;
    margin-left: 0.7rem !important;
    flex: 1;
    label {
        color: white;
        text-transform: uppercase;
    }
    .MuiInputBase-root {
        input {
            color: white;
        }
    }
}
.ct__memo {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    border-radius: 20px;
    padding: 1.5rem;
}
.ct__memo__bold {
    font-weight: bold;
    padding-bottom: 1.5rem;
}
.ct__memo__text {
    padding-bottom: 2.5rem;
}
.ct__memo__meeting {
    padding-bottom: 1.5rem;
}
.ct__memo__textContainer {
    display: flex;
}
.ct__memo__meeting__next {
    padding-bottom: 1rem;
}
.ct__memo__meeting__date {
    color: $progress-blue;
    margin-left: 1rem;
}

.ct__timeline {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    font-family: $Roboto;
    margin-bottom: 20px;
    margin-top: 20px;

    &__item {
    }

    &__circle {
        font-weight: bold;
        padding: 5px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.22);
        border-radius: 100%;
    }

    &__separator {
        flex-grow: 1;
        height: 2px;
        background-color: $progress-blue;
        margin: 0 5px;
    }
}

.cognitive-admin {
    .MuiFormControlLabel-label {
        color: rgba(0, 0, 0, 0.6);
    }
    svg {
        fill: rgba(0, 0, 0, 0.6);
    }
}

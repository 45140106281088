.pe {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    @include small {
      grid-template-columns: 1fr;
    }
  }
  &__daypicker {
    .DayPicker {
      margin: auto;
    }
  }
}
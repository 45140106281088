// #simple-tabpanel-0 {
//     background-color: rgba(0, 0, 0, 0.4);
//     border-radius: 20px;
// }
// .diet__container {
    //     background-color: red;
    // }

.diet {
    margin-bottom: 2rem;
    &__middle {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        margin: 40px 0;
        div {
            width: 100%;
        }
    }
}
.diet__days {
    background: linear-gradient(to right, #000, $portal-background 75%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    align-items: center;
    .Mui-selected {
        color: $progress-blue !important;
    }
    .MuiTabs-flexContainer {
        justify-content: space-evenly;
        gap: 10px;
    }
    .MuiTabs-indicator {
        display: none;
    }
    .MuiTab-textColorInherit {
        color: white !important;
    }
    .diet__days__week {
        background-color: $menu-color !important;
        border-radius: 20px !important;
        font-weight: 500 !important;
        font-size: 1.5rem !important;
        padding: 2px 0 !important;
        min-height: unset !important;
        width: calc(100%/3) !important;
        max-width: none !important;
        min-width: none !important;
        
    }
}
.diet__days__btn {
    background-color: $menu-color !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
    padding: 2px 0 !important;
    min-height: unset !important;
    width: calc(100%/8) !important;
    max-width: none !important;
    min-width: none !important;
    // padding-left: 50px !important;
    // padding-right: 50px !important;
}
.diet__meals {
    background-color: rgba($color: #000000, $alpha: 0.4);
    border-radius: 20px;
    position: relative;
    z-index: 2;
    padding-bottom: 2%;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: red;
    //     z-index: 1;
    // }
    & > * {
        &:nth-child(even) {
            margin-left: 1%;
            margin-right: 2%;
            
        }
    }
    & > * {
        &:nth-child(odd) {
            margin-left: 2%;
            margin-right: 1%;
        }
    }
    .diet__mealsContainer {
        max-width: 47%;
        // margin: 0 1%;
        flex-basis: 47%;
        padding-top: 2%;
        // margin-bottom: 2%;
    }

}
.mealCard {
    // margin: 2rem;
    background: linear-gradient(122.28deg, #01191B 0%, #1A3841 116.64%);
    width: 100%;
    height: 100%;
    border-radius: 20px;
    .MuiInput-input {
        color: white;
    }
    // .MuiInputBase-root {
    //     &::before {
    //         border-bottom: 1px solid white;
    //     }
    // }
}
.mealCard__iconContainer {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    border-radius: 20px;
    
}
.mealCard__icon {
    max-width: 75%;
    object-fit: contain;
}
.mealCard__content {
    padding: 1rem;
}
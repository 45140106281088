.homeCard__container {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    cursor: pointer;
    color: white;
    font-family: $Roboto;
    text-align: center;
    flex-direction: column;
    border-radius: 20px;
    height: 100%;
    position: relative;
}
.homeCard__titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75px;
    width: 100%;
    background-color: $menu-color;
    border-radius: 20px;
    margin-bottom: 2rem;
}
.homeCard__title {
    padding: 0.3rem 0.5rem;
}
.homeCard__image {
    // padding: 1rem;
    min-height: 100px;  
    width: 30%;
    object-fit: contain;
    @include laptop {
        padding: 0.25rem;
        padding-top: 1rem;
    }
}
.draftjs__ {
    &container {
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 2px;
        cursor: text;
        padding: 16px;
        background: #fefefe;
        margin-bottom: 2em;
        min-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;

        .align-left > * {
            text-align: left;
        }
        .align-center > * {
            text-align: center;
        }
        .align-right > * {
            text-align: right;
        }
    }

    &controls {
        padding-bottom: 30px;
        &__custombutton {
            padding: 2px 2px 3px 3px;
            margin: 2px;
            cursor: pointer;
            font-size: 1.8em;
            font-weight: bold;
            cursor: pointer;

            // Prevent text highlighting
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        }

        &__icon {
            svg {
                width: 1.4em;
                height: 1.4em;
            }
            cursor: pointer;
            margin: 2px;
            padding: 2px 2px 3px 3px;
        }

        &__image {
            display: flex;
            justify-content: space-around;
            background: #ededed;
            &__icon {
                padding-left: 5px;
                padding-right: 5px;
                cursor: pointer;
                font-size: 2.6em;

                &:hover {
                    background: #ddd;
                }
            }
        }
    }

    &alignment {
        color: red;
        display: flex;
        justify-content: center;
    }

    &hyperlink {
        color: $hyperlink;
        text-decoration: underline;

        #urlInputContainer {
            margin-bottom: 10px;
        }

        #urlInput {
            font-family: "'Georgia', serif";
            margin-right: 10px;
            padding: 3px;
        }
    }

    &redframe {
        padding: 20px;
        background-color: lightcoral;
    }

    &block {
        &__highlight {
            background-color: #f9f9f9;
            border-left: 3px solid $lefranco-blue;
            border-radius: 2px;
            // padding: 2.5em 20px 15px 2.5em;
            margin: 1.5em 10px;
            padding: 31px 30px 31px 30px;
            // p {
            //     display: inline;
            // }
        }

        // &__highlight:before {
        //     color: #ccc;
        //     content: "\201C";
        //     font-size: 4em;
        //     line-height: 0.1em;
        //     margin-right: 0.25em;
        //     vertical-align: -0.4em;
        // }

        // &__highlight:after {
        //     color: #ccc;
        //     content: "\201D";
        //     font-size: 4em;
        //     line-height: 0.1em;
        //     margin-left: 0.25em;
        //     vertical-align: -0.7em;
        // }

        &__color {
            background-color: $primary;
            border: 1px solid black;
            border-radius: 2px;
            padding: 15px;
            margin: 1.5em 10px;
        }

        &__graybox {
            background-color: #8a8a8a;
            border: 1px solid black;
            border-radius: 2px;
            padding: 15px;
            margin: 1.5em 10px;
        }

        &__pink {
            color: pink;
        }

        &__image {
            cursor: pointer;
            max-width: 200px;
        }

        &__blockquote {
            padding-left: 20px;
        }
    }

    &facebook {
        border: none;
        overflow: hidden;
    }
}

.public-DraftStyleDefault-block {
    span {
        color: black;
    }
}

.public-DraftStyleDefault-ltr .draftjs__controls {
    top: 500px;
    padding: 10px;
    margin-bottom: 10px;

    box-shadow: 3px 2px 5px #d3d3d3;
    border: 1px solid #d3d3d3;
    display: flex;
    border-radius: 2px;

    position: fixed;
    background-color: white;
    border-radius: 10px;
    margin: auto;
    width: fit-content;
    width: -moz-fit-content;
}

.mc {
    margin: 2px;
    display: flex;
    flex-direction: column;
    // padding: 1rem 0 0 0;
    cursor: pointer;
    height: 100%;
    background-color: $menu-color;
    border-radius: 20px;
}
.mc__childContainer {
    padding: 1rem 1rem 0 1rem;
}
.mc__titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75px;
    width: 100%;
}
.mc__title {
    text-align: center;
    max-width: 85%;
}

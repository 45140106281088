* {
    margin: 0;
}

body {
    background-color: $splashscreen-background;
}

.app__content {
    padding: 1.5rem;
    .MuiPaper-root {
        padding: 1rem;
    }
}

.nav__header__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
@media screen and (max-width: 960px) {
    .nav__header__container {
        justify-content: space-between;
        .MuiSvgIcon-root {
            color: $drawer-color;
        }
    }
}
.nav__header__options {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table__wrapper {
    & > div {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    cursor: pointer;
}

.centered__parent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered__parent__vertically {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.file-image {
    filter: invert(45%) sepia(12%) saturate(1083%) hue-rotate(135deg) brightness(92%) contrast(87%);
    max-height: 50px;
    margin-top: 5px;
    max-width: 50px;
}

// Custom Scrollbar
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $main-background;
}

.MuiPaper-elevation1 {
    padding: 20px !important;
    border-radius: 5px !important;

    -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
    -moz-box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
    box-shadow: rgba(0, 0, 0, 0.01) 0 1px 1px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    border: 1px solid $primary;
}

.css-mkzahr-MuiListSubheader-root {
    background-color: $drawer-color !important;
}

.MuiButton-root {
    text-transform: none !important;
    &:hover {
        background-color: unset !important;
    }
}

// change theme
.inverse {
    filter: invert(100%);
    .nofilterimg {
        filter: invert(100%) !important;
    }
    .cancelnofilterimg {
        filter: invert(100%);
    }
}

// Fonts
html {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
textarea,
li {
    font-family: $Roboto;
    color: white;
}
.font1 {
    h1 {
        font-size: 1.75rem;
        @include laptop {
            font-size: 1.63rem;
        }
        @include tablet {
            font-size: 1.5rem;
        }
    }
    h2 {
        font-size: 1.63rem;
        @include laptop {
            font-size: 1.5rem;
        }
        @include tablet {
            font-size: 1.38rem;
        }
    }
    h3 {
        font-size: 1.5rem;
        @include laptop {
            font-size: 1.38rem;
        }
        @include tablet {
            font-size: 1.25rem;
        }
    }
    h4 {
        font-size: 1.38rem;
        @include laptop {
            font-size: 1.25rem;
        }
        @include tablet {
            font-size: 1.13rem;
        }
    }
    h5 {
        font-size: 1.25rem;
        @include laptop {
            font-size: 1.13rem;
        }
        @include tablet {
            font-size: 1rem;
        }
    }
    h6 {
        font-size: 1.13rem;
        @include laptop {
            font-size: 1rem;
        }
        @include tablet {
            font-size: 0.87rem;
        }
    }
    p,
    span,
    textarea,
    li {
        font-size: 1rem;
        @include laptop {
            font-size: 0.87rem;
        }
        @include tablet {
            font-size: 0.75rem;
        }
    }
    option {
        font-size: 1rem;
    }
    input {
        font-size: 1rem;
    }
    label {
        font-size: 1rem;
    }

    th,
    tr,
    td {
        font-size: 1rem;
    }
}
.font2 {
    h1 {
        font-size: 2rem;
        @include laptop {
            font-size: 1.87rem;
        }
        @include tablet {
            font-size: 1.75rem;
        }
    }
    h2 {
        font-size: 1.87rem;
        @include laptop {
            font-size: 1.75rem;
        }
        @include tablet {
            font-size: 1.63rem;
        }
    }
    h3 {
        font-size: 1.75rem;
        @include laptop {
            font-size: 1.63rem;
        }
        @include tablet {
            font-size: 1.5rem;
        }
    }
    h4 {
        font-size: 1.63rem;
        @include laptop {
            font-size: 1.5rem;
        }
        @include tablet {
            font-size: 1.38rem;
        }
    }
    h5 {
        font-size: 1.5rem;
        @include laptop {
            font-size: 1.38rem;
        }
        @include tablet {
            font-size: 1.25rem;
        }
    }
    h6 {
        font-size: 1.38rem;
        @include laptop {
            font-size: 1.25rem;
        }
        @include tablet {
            font-size: 1.13rem;
        }
    }
    p,
    span,
    textarea,
    li {
        font-size: 1.25rem;
        @include laptop {
            font-size: 1.13rem;
        }
        @include tablet {
            font-size: 1rem;
        }
    }
    option {
        font-size: 1.25rem;
    }
    input {
        font-size: 1.25rem;
    }
    label {
        font-size: 1.25rem;
    }
    th,
    tr,
    td {
        font-size: 1.25rem;
    }
}
.font3 {
    h1 {
        font-size: 2.25rem;
        @include laptop {
            font-size: 2.13rem;
        }
        @include tablet {
            font-size: 2rem;
        }
    }
    h2 {
        font-size: 2.13rem;
        @include laptop {
            font-size: 2rem;
        }
        @include tablet {
            font-size: 1.87rem;
        }
    }
    h3 {
        font-size: 2rem;
        @include laptop {
            font-size: 1.87rem;
        }
        @include tablet {
            font-size: 1.75rem;
        }
    }
    h4 {
        font-size: 1.87rem;
        @include laptop {
            font-size: 1.75rem;
        }
        @include tablet {
            font-size: 1.63rem;
        }
    }
    h5 {
        font-size: 1.75rem;
        @include laptop {
            font-size: 1.63rem;
        }
        @include tablet {
            font-size: 1.5rem;
        }
    }
    h6 {
        font-size: 1.63rem;
        @include laptop {
            font-size: 1.5rem;
        }
        @include tablet {
            font-size: 1.38rem;
        }
    }
    p,
    span,
    textarea,
    li {
        font-size: 1.5rem;
        @include laptop {
            font-size: 1.38rem;
        }
        @include tablet {
            font-size: 1.25rem;
        }
    }
    option {
        font-size: 1.5rem;
    }
    input {
        font-size: 1.5rem;
    }
    label {
        font-size: 1.5rem;
    }
    th,
    tr,
    td {
        font-size: 1.5rem;
    }
}

.MuiIconButton-root {
    svg {
        fill: white;
    }
}

// Cta
.cta__blue {
    background-color: $progress-blue !important;
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    justify-content: space-between !important;
    border-radius: 20px !important;

    &:hover {
        background-color: $menu-color !important;
        color: white !important;
    }
}
.cta__submit {
    color: black !important;
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    border-radius: 20px !important;
    background-color: $progress-blue !important;
    &:hover {
        background-color: $menu-color !important;
        color: white !important;
    }
}
.cta__back {
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    border-radius: 20px !important;
    border: 1px solid $progress-blue !important;
    background-color: #00000000 !important;
    color: white !important;
    &:hover {
        color: white !important;
        background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    }
}

// TablePagination
.MuiTablePagination-root {
    .MuiTablePagination-displayedRows {
        color: black;
    }
}
// Dashboard calendar title
.fc-toolbar-title {
    color: black;
}

.MuiAutocomplete-popper {
    * {
        color: black;
    }
}

.scrollable__container {
    overflow-y: auto;
    max-height: calc(100vh - 228px);
}

.MuiTablePagination-actions {
    svg {
        fill: #616161;
    }
}

.MuiIconButton-root {
    svg {
        fill: #616161;
    }
}

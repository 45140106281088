.sleep {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    @include small {
        grid-template-columns: 1fr;
    }
}
.sleep__graph__title {
    margin: 1rem 0 1rem 2rem;
}
.sleep__graph {
    background-color: rgba(#000000, 0.4);
    border-radius: 20px;
    padding: 0 !important;
}
.sleep__graph__days {
    background-color: black;
    h6 {
        color: $progress-blue;
        margin: 1rem;
        text-align: center;
        font-weight: 500;
    }
    &:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    &:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}
.sleep__graph__data {
    .MuiLinearProgress-root {
        top: 33%;
        margin: 3px 2rem 0;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
}
.sleep__blackout {
    opacity: 0;
}

.sleep__buttons {
    display: flex;
    justify-content: space-between;
    & > * {
        margin: 0 !important;
    }
}

// Calendar
.DayPicker {
    background: linear-gradient(137.88deg, #000000 0%, #1a3841 76.26%);
    box-shadow: none;
    width: 395px !important;
    border-radius: 20px;
    div {
        background: unset;
    }
    .CalendarMonth_caption {
        color: white;
        font-size: 28px;
    }
    .DayPickerNavigation_button {
        // border: none;
        border-radius: 20px;
        svg {
            width: 1.75rem;
            height: 1.75rem;
        }
    }
    .DayPicker_weekHeader {
        background-color: black;
        border-radius: 20px;
        ul {
            padding: 1rem 0;
            small {
                color: $progress-blue;
                text-transform: uppercase;
            }
        }
    }
    .CalendarMonth_table {
        margin-top: 1.3rem;
        // padding: 0 13px 1rem;
    }
    .DayPicker_weekHeader_ul {
        display: flex;
        justify-content: center;
    }
    .DayPicker_weekHeader {
        padding: unset !important;
    }
    .DayPicker_weekHeader,
    .DayPicker_weekHeader_ul,
    .DayPicker_weekHeaders {
        width: 100%;
    }
    .DayPicker_weekHeaders__horizontal {
        margin-left: unset;
    }
    .DayPickerNavigation_button__disabled {
        border: 1px solid #e4e7e7;
    }
    .DayPickerNavigation_svg__disabled {
        fill: #82888a !important;
    }
    .DayPickerNavigation_svg__horizontal {
        fill: #f2f2f2;
    }
    .DayPickerNavigation_button__horizontalDefault {
        top: 11px;
    }
    .CalendarDay__default {
        background: none;
        border: none;
    }
    .CalendarDay {
        span[aria-label="Today"] {
            font-weight: lighter !important;
            color: white !important;
            background-color: #063336;
            border: 4.66912px solid #4ecbd3;
            border-radius: 50px;
            padding: 5px;
        }
        span[aria-label="Future date"] {
            color: black !important;
            font-weight: bold;
        }
    }
}

.surveyDialog {
    font-family: $Roboto;
    .MuiPaper-root {
        background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
        border-radius: 20px;
        padding: 0.5rem 1rem 1rem;
    }
}
.MuiDialogTitle-root {
    font-weight: bold !important;
    font-size: 2rem !important;
}
.MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}
.MuiDialogContent-root::-webkit-scrollbar {
    display: none;
  }
.surveyDialog__item {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.surveyDialog__logo {
    width: 30px;
    width: 30px;
    padding-right: .5rem;
}
// Dashboard
$primary: #90caf9;
$secondary: #ce93d8;
$drawer-color: #f5f5f5;
$appBar-color: #f5f5f5;
$main-background: #ececec;
$dashboard-text: #8a8a8a;
$dashboard-background: #f5f5f5;
$progress-blue: #4ECBD3;
$progress-red: #DC5353;

// Portal
$menu-color: #063336;
$portal-background: #1a3841;
$splashscreen-background: #1a3841;
$blue: #4ecbd3;
$portal-text: white;
$highlight: #4e797c;

// Draft-js
$lefranco-blue: #2080bb;
$background: #ececec;
$hyperlink: #0000ee;

// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Catamaran:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

$Roboto: "Roboto", sans-serif;
$Raleway: "Raleway", sans-serif;
$Catamaran: "Catamaran", sans-serif;
$Open-Sans: "Open Sans", sans-serif;
$Lato: "Lato", sans-serif;
$Montserrat: "Montserrat", sans-serif;

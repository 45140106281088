.topMenu__container {
    display: flex;
    // padding-top: 10px;
    padding-bottom: 1.5rem;
    // width: 100vh;

    // .topMenu__toolbar {
    //     padding-right: 24px;
    // }

    // .topMenu__menuButton {
    //     margin-right: 36px;
    // }

    // .topMenu__menuButton__hidden {
    //     display: none;
    // }

    .topMenu__appBar {
        // z-index: 1201;
        // transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        background-color: $menu-color !important;
        border-radius: 20px;
    }

    // .topMenu__appBar__shift {
    //     width: calc(100% - 240px);
    //     transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    //     margin-left: 240px;
    // }

    .topMenu__elements {
        justify-content: space-between;
    }

    .topMenu__user {
        display: flex;
        align-items: center;
    }

    .topMenu__title {
        font-family: $Roboto;
        padding-left: 20px;
        // font-size: 2rem;
        flex-grow: 1;
        color: $portal-text;
    }

    // .topMenu__menu__icon {
    //     color: $portal-text;
    // }

    // .topMenu__paper {
    //     width: 240px;
    //     position: relative;
    //     transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    //     white-space: nowrap;
    // }

    // .topMenu__paper__close {
    //     width: 72px;
    //     overflow-x: hidden;
    //     transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    // }

    // .topMenu__toolbaricon {
    //     display: flex;
    //     padding: 0 8px;
    //     min-height: 64px;
    //     align-items: center;
    //     justify-content: flex-end;
    // }
}

.topMenu__list__mobile {
    width: 250px;
}

.topMenu__nav {
    color: black;

    .topMenu__nav__item {
        .MuiListItem-root {
            padding-left: 1.5rem;
        }

        text-decoration: none;

        .MuiTypography-body1 {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
        }

        svg {
            height: 1.8em;
            width: 1.8em;
        }

        &.active {
            .MuiListItem-root {
                background-color: $portal-text;
            }
        }
    }

    .MuiTreeItem-content {
        padding-left: 10px;
    }
    .MuiTreeItem-root {
        padding-bottom: 10px;
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
        background: none;
    }
}

.MuiDrawer-paper {
    background-color: $drawer-color !important;
}

.nav__logo__container {
    width: 100%;
    img {
        max-width: 150px;
        padding-left: 2rem;
        padding-top: 0.3rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // ALPHA
    .MuiChip-root {
        font-weight: bold;
        color: $drawer-color;
        // font-size: 10px;
        height: 22px;
        span {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    .nav__logo {
        max-height: 62px;
        width: 100%;
    }
}

.topMenu__cta {
    padding: 0 !important;
    .topMenu__cta__left {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #fee98480;
        color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        @include laptop {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .topMenu__cta__right {
        height: 100%;
        padding-right: 1.75rem;
        padding-left: 1.25rem;
        display: flex;
        align-items: center;
        background: linear-gradient(180.38deg, rgba(248, 218, 110, 0.8) 0.33%, rgba(211, 211, 55, 0.096) 99.67%);
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;

        .topMenu__trophy {
            height: 70%;
            @include laptop {
                height: 60%;
            }
        }
    }
}

.topMenu__logout {
    padding: 0 !important;
    .topMenu__logout__left {
        display: flex;
        align-items: center;
        height: 100%;
        background: linear-gradient(137.88deg, #000000 0%, #1a3841 76.26%);
        color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        @include laptop {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .topMenu__logout__right {
        height: 100%;
        padding-right: 1.75rem;
        padding-left: 1.25rem;
        display: flex;
        align-items: center;
        background-color: #4ecbd3;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        @include laptop {
            padding-right: 1.5rem;
            padding-left: 1rem;
        }

        svg {
            font-size: 1.8rem;
            color: white;
            @include laptop {
                font-size: 1.5rem;
            }
        }
    }
}
.topMenu {
    &__fontsize {
        .MuiFormControl-root {
            .Mui-focused {
                option {
                    padding: 5px;
                }
            }
        }
        padding: 16px 0;
        .MuiNativeSelect-select {
            color: white;
        }

        label {
            color: white;
        }
        fieldset {
            border-color: white;
        }
        svg {
            color: white;
        }
        .MuiInputBase-root::after {
            border-bottom: unset;
        }
    }
    &__font1 {
        font-size: 15px !important;
        color: black;
        padding: 20px;
    }
    &__font2 {
        font-size: 20px !important;
        color: black;
        padding: 20px;
    }
    &__font3 {
        font-size: 25px !important;
        color: black;
        padding: 20px;
    }
}

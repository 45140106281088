.portalLayout__container {
    height: 100vh;

    .portalLayout__title {
        color: white;
        font-family: $Roboto;
        // font-size: 24px;
    }

    .portalLayout__subtitle {
        cursor: pointer;
        border: 1px solid $menu-color;
        color: white;
        font-family: $Roboto;
        // font-size: 16px;
        text-align: center;
        margin: 5px;
        min-height: 225px;
        .title {
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: $menu-color;
            border-radius: 5px;
        }
        .image {
            padding: 30px;
            min-height: 125px;
        }
    }

    // .portalLayout__content {
    //     height: 100vh;
    //     overflow: auto;
    //     flex-grow: 1;
    // }

    .portalLayout__appBarSpacer {
        min-height: 64px;
    }

    // .MuiDrawer-paperAnchorDockedLeft {
    //     background-color: $drawer-color;
    // }

    // .MuiAppBar-root {
    //     background-color: $appBar-color !important;
    // }
    // .MuiPaper-elevation4 {
    //     box-shadow: none;
    // }
    // .MuiDrawer-paperAnchorDockedLeft {
    //     border-right: none;
    // }
}
.portalLayout__spacing {
    padding: 0.8rem 5rem !important;
    @include laptop {
        padding: 2rem 3rem !important;
    }
    @include tablet {
        padding: 1.5rem !important;
    }
}
.portalLayout__background {
    background: linear-gradient(to bottom right, #000, $portal-background 75%) !important;
    height: 100%;
    min-height: calc(100vh - 26px);
}
.portalLayout__dialog {
    .MuiDialog-paper {
        background-color: $portal-background;
        border-radius: 20px;
    }
}

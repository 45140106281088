.infinitescroll__container {
    &__card {
        padding: 10px 0px 10px 0px;
        margin: 0px 0px 5px 0px;
        background-color: #eeeeee;
        border-radius: 5px;
        cursor: pointer;
    }

    &__card:last-child {
        margin: 0px 0px 0px 0px;
    }

    &__text {
        color: #616161;
        font-weight: bold;
    }

    &__colname {
        color: #616161;
    }

    &__message {
        color: #616161;
        text-align: center;
    }
}

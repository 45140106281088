.drawer__container {
    display: flex;
    color: $portal-text !important;

    .drawer__toolbar {
        padding-right: 24px;
    }

    .drawer__menuButton {
        margin-right: 36px;
    }

    .drawer__menuButton__hidden {
        display: none;
    }

    .drawer__appBar {
        z-index: 1201;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    .drawer__appBar__shift {
        width: calc(100% - 240px);
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        margin-left: 240px;
    }

    .drawer__title {
        flex-grow: 1;
        color: $dashboard-text;
    }

    .drawer__menu__icon {
        color: $dashboard-text;
    }

    .drawer__paper {
        width: 240px;
        position: relative;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        white-space: nowrap;
    }

    .drawer__paper__close {
        width: 72px;
        overflow-x: hidden;
        transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }

    .drawer__toolbaricon {
        svg {
            fill: #616161;
        }
        display: flex;
        padding: 0 8px;
        min-height: 64px;
        align-items: center;
        justify-content: flex-end;
    }
}

.drawer__list__mobile {
    width: 250px;
}

.drawer__nav {
    color: black;

    .drawer__nav__item {
        .MuiListItem-root {
            padding-left: 1.5rem;
        }

        text-decoration: none;

        .MuiTypography-body1 {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
        }

        svg {
            height: 1.8em;
            width: 1.8em;
        }

        &.active {
            .MuiListItem-root {
                background-color: $dashboard-text;
            }
        }
    }

    .MuiTreeItem-content {
        padding-left: 10px;
    }
    .MuiTreeItem-root {
        padding-bottom: 10px;
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
        background: none;
    }
}

.MuiDrawer-paper {
    background-color: $drawer-color !important;
}

.nav__logo__container {
    width: 100%;
    img {
        max-width: 150px;
        padding-left: 2rem;
        padding-top: 0.3rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // ALPHA
    .MuiChip-root {
        font-weight: bold;
        color: $drawer-color;
        font-size: 10px;
        height: 22px;
        span {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    .nav__logo {
        max-height: 62px;
        width: 100%;
        padding-left: 2rem;
        color: $menu-color;
    }
}

.health {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;
    @include small {
        grid-template-columns: 1fr;

    }
}
.health__files {
    margin-bottom: 1rem;
}
@mixin computer {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin laptop {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin small {
    @media only screen and (max-width: 960px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin phone {
    @media only screen and (max-width: 600px) {
        @content;
    }
}
